<template>
	<div>
		<div class="syitem" v-for="(item,idx) in DataList" @click="ziXun(item)">
			{{item.cdate}} 提现 <span style="color: orangered;">￥{{item.tx_money}}</span>
		</div>
		
		<div style="text-align: center; line-height: 50px;" v-if="DataList.length==0">暂无数据</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: "",
				DataList: []

			}
		},

		mounted() {

			this.getList();
		},
		methods: {
			getList() {
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=txhistory"
				}).then(result => {

					this.DataList = result.data.rows;
					$("#loading").hide()
				})
			},
			searchData() {
				sessionStorage.setItem("zxkeyword", this.keyword)
				this.getList()
			},
			ziXun(item) {
			
			}
		}
	}
</script>

<style scoped="scoped">
	.syitem{ padding: 15px; border-bottom: 1px solid #f6f6f6;}
</style>
